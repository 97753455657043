<template>
    <a-layout-content class="Question">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <a-layout :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <a-layout-sider width="200" style="background: #fff">
                <a-menu
                        mode="inline"
                        :defaultSelectedKeys="[this.$route.path]"
                        :defaultOpenKeys="[this.$route.path]"
                        style="height: 100%"
                        @click="groupFilterClick"
                >
                    <a-menu-item key="0">
                        <h2>Nhóm câu hỏi</h2>
                    </a-menu-item>
                    <a-menu-item key="/cau-hoi/ds-cau-hoi">
                        <router-link :to="{ path: '/ds-cau-hoi'}" class="nav-link">
                            <a-icon type="appstore" />
                            <span>Tất cả</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item v-for="group in getCategories" :key="'/ds-cau-hoi/nhom/' + group.id">
                        <router-link :to="{ path: '/ds-cau-hoi/nhom/' + group.id }" class="nav-link">
                            <a-icon type="book" />
                            <span>{{ group.name }}</span>
                        </router-link>
                    </a-menu-item>
                </a-menu>
            </a-layout-sider>
            <a-layout-content :style="{ padding: '0 24px' }">
                <div class="content-header">
                    <div class="content-header__right">
                        <div class="bulk-action">
                            <span>Bulk action:</span>
                            <a-select :disabled="!bulkSelected" labelInValue placeholder="Chọn action" style="width: 160px; margin-left: 15px " @change="handleActionSelected">
                                <a-select-option value="delete">Xóa {{ selectedRows.length }} câu hỏi</a-select-option>
                                <a-select-option value="publish">Publish {{ selectedRows.length }} câu hỏi</a-select-option>
                                <a-select-option value="unpublish">UnPublish {{ selectedRows.length }} câu hỏi</a-select-option>
                            </a-select>
                            <a-popconfirm title='Bạn có chắc chắn muốn thực hiện?' @confirm="handleBulkActionConfirm">
                                <a-button  :disabled="!bulkSelected" style="margin-left: 15px" type="primary">Thực hiện</a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="content-header__left">
                            <a-button type="primary" icon="plus-circle" @click="handleVisibleForm">Thêm câu hỏi</a-button>

                        <a-input-search
                                placeholder="Nhập ID hoặc nội dung câu hỏi..."
                                style="width: 350px; margin-left: 30px"
                                @search="handleSearch"
                        />
                    </div>
                </div>
                <a-divider />
                <question-list-table :loading="loading" v-on:deleteItem="handleDeleteQuestion" v-on:multiSelected="handleMultiSelected" @publishChange="publishChange" @updateQuestion="handleUpdateQuestion" ref="questionList" :questions="questions" />
                <div v-if="!(pageResponse.first && pageResponse.last) && questions.length !== 0" class="pagination">
                    <a-pagination v-if="!onSearch" :current="currentPage" @change="handlePaginationChange" :pageSize="pageResponse.size" :total="pageResponse.totalElements" />
                </div>
            </a-layout-content>
        </a-layout>
        <question-form :currentQuestion="currentQuestion" ref="questionAddForm" @cancel="handleFormCancel" @submit="handleFormSubmit" :visible="visibleAddNew" />
    </a-layout-content>
</template>

<script>
    import "./_Question.scss"
    import { mapGetters } from "vuex";
    import {RepositoryFactory} from "@/repository/RepositoryFactory";
    import QuestionListTable from "@/components/QuestionListTable";
    import QuestionForm from "@/components/drawer/QuestionForm";
    const questionRepository = RepositoryFactory.get("question");

    export default {
        components: {QuestionListTable, QuestionForm},
        data() {
            return {
                questions: [],
                search: '',
                selectedRows: [],
                bulkSelected: false,
                actionSelected: null,
                pageResponse: {},
                numberOfPage: 10,
                sort: 'desc',
                currentPage: 1,
                loading: true,
                visibleAddNew: false,
                currentQuestion: null,
                onSearch: false
            }
        },
        methods: {
            handleDeleteQuestion: function(id) {
                // eslint-disable-next-line no-console
                console.log(id);
            },
            handleMultiSelected: function ({selectedRows}) {
                if (selectedRows.length > 0) {
                    this.bulkSelected = true;
                } else {
                    this.bulkSelected = false;
                    this.actionSelected = null;
                }
                this.selectedRows = selectedRows;
            },
            handleActionSelected: function (value) {
                this.actionSelected = value;
            },
            handleBulkActionConfirm:  async function () {
                if (this.bulkSelected && this.actionSelected) {
                    // eslint-disable-next-line no-console
                    let key = Object.assign({}, this.actionSelected).key;
                    // eslint-disable-next-line no-console
                    console.log([...this.selectedRows.map(item => Object.assign({}, item))], key);
                    await this.$message.info("Tính năng chưa hoàn thiện !");
                }
            },

            getQuestion: async function ({page, group}) {
                let questionResponse = {};
                if (group) {
                    questionResponse = await questionRepository.getQuestionInGroupByPage(group.id, page);
                } else {
                    questionResponse = await questionRepository.getQuestionsByPage(page);
                }
                if (!questionResponse.data.meta.success) throw new Error("Question data" + questionResponse.data.meta.externalMessage);
                this.pageResponse = questionResponse.data.data;
                this.questions = questionResponse.data.data.content;
            },
            groupFilterClick: async function ({key}) {
                try {
                    this.loading = true;
                    this.onSearch = false;
                    this.currentPage = 1;
                    if (key === 'all' || !this.$route.params.groupId) await this.getQuestion({page: {page: 0, size: this.numberOfPage, sort: `id,${this.sort}`}});
                    if (this.$route.params.groupId) await this.getQuestion({page: {page: 0, size: this.numberOfPage, sort: `id,${this.sort}`}, group: { id: this.$route.params.groupId}})
                } catch (e) {
                    await this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            },
            handlePaginationChange: async function(pageNumber) {
                try {
                    this.currentPage = pageNumber;
                    this.loading = true;
                    if (this.$route.params.groupId) {
                        await this.getQuestion({page: {page: pageNumber - 1, size: this.numberOfPage, sort: `id,${this.sort}`}, group: { id: this.$route.params.groupId}})
                    }  else {
                        await this.getQuestion({page: {page: pageNumber - 1, size: this.numberOfPage, sort: `id,${this.sort}`}});
                    }
                } catch (e) {
                    await this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            },
            handleVisibleForm() {
                this.visibleAddNew = true;
            },
            handleFormCancel() {
                this.visibleAddNew = false;
            },
            handleUpdateQuestion(id, question) {
                this.currentQuestion = question;
                this.visibleAddNew = true;
            },
            async handleFormSubmit(question) {
                try {
                    this.loading = true;
                    this.$refs.questionAddForm.editable = false;
                    let { data } = await questionRepository.createQuestion(question);
                    if (!data.meta.success) throw new Error(data.meta.externalMessage);
                    this.visibleAddNew = false;
                    this.$message.success('Thêm mới/cập nhật câu hỏi thành công!');
                } catch (e) {
                    this.openNotification(e.message)
                } finally {
                    await this.fetchQuestions();
                    this.$refs.questionAddForm.editable = true;
                }
            },
            handleChangeOnActive (value, id, column) {
                const newData = [...this.questions];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.questions = newData;
                }
            },
            async publishChange(id, record) {
                record.publish = !record.publish;
                await this.saveSetting(id, record);
                this.handleChangeOnActive(record.publish, id, 'publish')
            },
            async saveSetting (id, record) {
                try {
                    this.loading = true;
                    let response = await questionRepository.updateQuestion(id, record);
                    if (!response.data.meta.success) throw new Error(response.data.meta.externalMessage)
                    const newData = [...this.questions];
                    const target = newData.filter(item => record.id === item.id)[0];

                    if (target) {
                        this.questions = newData;
                    }
                } catch (e) {
                    this.openNotification(e.message);
                } finally {
                    this.loading = false;
                }
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            async fetchQuestions() {
                try {
                    this.loading = true;
                    if (this.$route.params.groupId) {
                        await this.getQuestion({page: {page: 0, size: this.numberOfPage, sort: `id,${this.sort}`}, group: { id: this.$route.params.groupId}})
                    } else {
                        await this.getQuestion({page: {page: 0, size: this.numberOfPage, sort: `id,${this.sort}`}})
                    }
                } catch (e) {
                    await this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            },
            async searchQuestionByContent(content, groupId) {
                let questionResponse = {};
                if (groupId) {
                    questionResponse = await questionRepository.searchInGroupByContent(groupId, content);
                } else {
                    questionResponse = await questionRepository.searchByContent(content);
                }
                if (!questionResponse.data.meta.success) throw new Error("Question data" + questionResponse.data.meta.externalMessage);
                return questionResponse.data.data;
            },
            async findById(questionId) {
                let questions = [];
                let { data } = await questionRepository.getSingleQuestion(questionId);
                if (data.meta.success) {
                    questions.push(data.data)
                }
                return questions;
            },
            async handleSearch(value) {
                try {
                    let questions = [];
                    this.loading = true;
                    this.onSearch = true;
                    if (Number.isInteger(parseInt(value))) {
                        questions = await this.findById(parseInt(value))
                    } else {
                        questions = await this.searchQuestionByContent(value, this.$route.params.groupId)
                    }
                    this.questions = questions;
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                    this.questions = [];
                } finally {
                    this.loading = false;
                }
            }
        },
        watch: {
            visibleAddNew(value) {
                if (!value) {
                    this.currentQuestion = null;
                }
            }
        },
        computed: {
            ...mapGetters('staticData', [
                'getCategories'
            ])
        },
        async created() {
            await this.fetchQuestions();
        }
    };
</script>
