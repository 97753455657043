<template>
    <a-drawer
            title="Câu hỏi"
            :width="820"
            :visible="visible"
            closable
            @close="() => {$emit('cancel')}"
            :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
    >
        <a-form :form="form" layout="vertical" hideRequiredMark>
            <a-form-item label="Nội dung câu hỏi:">
                <a-textarea :disabled="!editable" v-decorator="['content', { rules: [{ required: true, message: 'Vui lòng nhập nội dung câu hỏi!' }] }]" :rows="3"/>
            </a-form-item>
            <a-form-item label="Nhóm câu hỏi:">
                <a-input type="hidden" v-decorator="['group', { rules: [{ required: true, message: 'Vui lòng chọn nhóm câu hỏi!' }] }]" />
                <a-select :disabled="!editable" ref="categorySelect" v-model="categoryIdSelected" placeholder="Chọn nhóm câu hỏi" style="width: 200px">
                    <a-select-option v-for="category in getCategories" :key="category.id">
                        {{ category.code }}-{{ category.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-divider/>
            <div class="header">
                <h3>Danh sách câu trả lời</h3>
                <a-button :disabled="!editable" icon="plus-circle" type="primary" @click="addNewAnswer">Thêm mới</a-button>
            </div>
            <a-form-item>
                <a-input type="hidden" v-decorator="['answers']" />
                <a-table :columns="columns" :dataSource="answersW" bordered :rowKey="(record, index) => index" :loading="loading" :pagination="false" >
                    <template v-for="col in ['content']" :slot="col" slot-scope="text, record">
                        <div :key="col">
                            <a-textarea
                                    v-if="record.editable"
                                    style="margin: -5px 0"
                                    :value="text"
                                    @change="e => handleChange(e.target.value, record.index, col)"
                                    row="2"
                            />
                            <template v-else>{{text}}</template>
                        </div>
                    </template>
                    <template slot="correctAnswer" slot-scope="text, record">
                        <a-checkbox :disabled="!editable" :checked="record.correctAnswer" @change="() => correctAnswerChange(record)"/>
                    </template>
                    <template slot="operation" slot-scope="text, record">
                        <div class='editable-row-operations'>
                            <span v-if="!record.editable"  style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                              <a-button :disabled="!editable" @click="() => edit(record.index)" icon="edit"></a-button>
                            </span>

                            <span v-if="record.editable" style="margin-left: 10px; display: flex; flex-direction: row; justify-content: center; align-items: center; margin-bottom: 10px;">
                                <a-button :disabled="!editable" type="primary" style="display: inline-block;" @click="() => saveGroup(record)" icon="save" />
                            </span>

                            <span v-if="!record.editable"  style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                                <a-popconfirm title='Bạn có chắc chắn muốn xóa?' @confirm="() => deleteAnswer(record.index)">
                                    <a-button :disabled="!editable" type="danger" icon="delete"></a-button>
                                </a-popconfirm>
                            </span>
                        </div>
                    </template>
                </a-table>
            </a-form-item>
        </a-form>
        <div
            :style="{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }"
        >
            <a-button :disabled="!editable" @click="() => { $emit('cancel') }" :style="{marginRight: '8px'}">
                Hủy
            </a-button>
            <a-button :disabled="!editable" @click="submit" type="primary" icon="save">Lưu lại</a-button>
        </div>
    </a-drawer>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "QuestionForm",
        props: ["visible", "currentQuestion"],
        data() {
            return {
                form: this.$form.createForm(this),
                answers: [],
                content: undefined,
                cacheData: [],
                loading: false,
                categoryIdSelected: undefined,
                editable: false,
                columns: [
                    {
                        title: 'Nội dung câu trả lời',
                        dataIndex: 'content',
                        width: '70%',
                        scopedSlots: { customRender: 'content' },
                    },{
                        title: 'Đáp án đúng',
                        dataIndex: 'correctAnswer',
                        width: '12%',
                        scopedSlots: { customRender: 'correctAnswer' },
                    }, {
                        title: 'Action',
                        dataIndex: 'operation',
                        scopedSlots: { customRender: 'operation' },
                    }
                ]
            };
        },
        computed: {
            ...mapGetters('staticData', [
                'getCategories'
            ]),
            answersW() {
                return this.answers.map((answer, index) => {
                    return {
                        ...answer,
                        index
                    }
                })
            }
        },
        watch: {
            visible(value) {
                if (!value) {
                    this.form.resetFields();
                    this.answers = [];
                    this.categoryIdSelected = undefined;
                    this.editable = false;
                } else {
                    this.editable = true;
                }
            },
            currentQuestion(question) {
                if (question) {
                    this.form.getFieldDecorator('content', { initialValue: question.content });
                    this.form.setFieldsValue({
                        answers: JSON.stringify(question.answers),
                        group: JSON.stringify(question.group)
                    });

                    this.answers = question.answers;
                    this.categoryIdSelected = question.group.id;
                } else {
                    this.form.getFieldDecorator('content', { initialValue: undefined });
                }
            }
        },
        methods: {
            addNewAnswer() {
                this.answers.push({
                    content: '',
                    correctAnswer: false,
                    editable: true
                })
            },
            edit(index) {
                const newData = [...this.answers];
                const target = newData[index];
                if (target) {
                    target.editable = true;
                    this.answers = newData;
                }
            },
            deleteAnswer(index){
                this.answers = this.answers.filter((answer, i) => i !== index );
                this.cacheData = this.answers.map(item => ({ ...item }));
            },
            handleChange (value, index, column) {
                const newData = [...this.answers];
                const target = newData[index];
                if (target) {
                    target[column] = value;
                    this.answers = newData;
                }
            },
            async saveGroup (record) {
                const newData = [...this.answers];
                const target = newData[record.index];
                if (target) {
                    delete target.editable;
                    this.answers = newData;
                    this.cacheData = newData.map(item => ({ ...item }));
                }
            },
            correctAnswerChange(record) {
                record.correctAnswer = !record.correctAnswer;
                this.handleChange(record.correctAnswer, record.index, 'correctAnswer')
            },
            submit() {
                try {
                    let cat = this.getCategories.find( category => category.id === this.categoryIdSelected );
                    this.form.setFieldsValue({
                        answers: JSON.stringify(this.answers),
                        group: JSON.stringify(cat)
                    });

                    this.form.validateFields(async (err, values) => {
                        if (err) {
                            return;
                        }

                        let question = values;
                        question.group = JSON.parse(question.group);
                        question.answers = JSON.parse(question.answers);

                        if (this.currentQuestion) {
                            question = {
                                ...this.currentQuestion,
                                content: question.content,
                                group: question.group,
                                answers: question.answers
                            };
                        }
                        this.$emit('submit', question);
                    });
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e)
                } finally {
                    this.editable = true;
                }
            }
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .editable-row-operations {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>