<template>
    <div>
        <a-table :columns="columns" :dataSource="questions" bordered :rowKey="(record, index) => record.id" :loading="loading" :rowSelection="rowSelection"
                 :pagination="false" >
            <template slot="ispublish" slot-scope="text, record">
                <a-popconfirm title='Bạn có chắc chắn?' @confirm="() => unPublishQuestion(record.id, record)">
                    <a-checkbox :checked="record.publish" />
                </a-popconfirm>
            </template>
            <template slot="operation" slot-scope="text, record">
                <div class='editable-row-operations'>
                    <span style="margin-left: 10px">
                        <a-button @click="() => updateQuestion(record.id, record)">Cập nhật</a-button>
                    </span>
                </div>
            </template>
            <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
                <a-card :title="`Câu hỏi: ${record.content}`">
                    <a-card-grid v-for="answer in record.answers" v-bind:key="answer.id" style="width:50%;textAlign:'left'">
                        <a-checkbox :checked="answer.correctAnswer"/><span>{{answer.content}}</span>
                    </a-card-grid>
                </a-card>
            </div>
        </a-table>
    </div>
</template>

<script>

    const columns = [
    {
        title: 'Id',
        dataIndex: 'id',
        width: '5%',
        scopedSlots: { customRender: 'id' },
    },{
        title: 'Nội dung câu hỏi',
        dataIndex: 'content',
        width: '55%',
        scopedSlots: { customRender: 'content' },
    }, {
        title: 'Nhóm câu hỏi',
        dataIndex: 'group.name',
        width: '15%',
        scopedSlots: { customRender: 'group' },
    }, {
        title: 'Publish',
        dataIndex: 'publish',
        width: '5%',
        scopedSlots: { customRender: 'ispublish' },
    }, {
        title: 'Action',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
    }];

    export default {
        name: "QuestionListTable",
        props: ['questions', 'loading'],
        data() {
            return {
                visible: false,
                columns,
                rowSelection: {
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.$emit('multiSelected', {selectedRowKeys, selectedRows})
                    }
                }
            }
        },
        methods: {
            unPublishQuestion: async function(id, record) {
                this.$emit('publishChange', id, record)
            },
            updateQuestion(id, record) {
                this.$emit('updateQuestion', id, record)
            }
        }
    }
</script>

<style scoped>

</style>